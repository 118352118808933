
import React from "react";
import { Styled } from "theme-ui";

/**
 * Shadow me to add your own bio content
 */

export default () => (
  <>
    Hello! 💚
    <br />
    This is <Styled.a href="https://github.com/jeddf">Jedd's</Styled.a> <Styled.a href="https://twitter.com/jeddfenner">personal</Styled.a> <Styled.a href="https://jedd.pw">blog</Styled.a>, about being human & making valuable web stuff.
  </>
)